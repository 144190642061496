.box {
  background: linear-gradient(
    rgba(255, 255, 255, 0.14) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  padding: 1em;
  border-radius: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 90%;
  // height: 200px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-bottom: 1em;
  transition: opacity 0.3s ease 0s;

  h4 {
    font-size: 20px;
    margin: 0px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 0.5em;
    color: rgb(212, 212, 212);
    font-weight: 500;
  }
  .img-obj {
    margin-right: 15px;
    width: 70px;
    height: 70px;
    position: relative;
    img {
      position: absolute;
      inset: 0px;
      box-sizing: border-box;
      padding: 0px;
      border: none;
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.box:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
