.img-hero {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.btnmain {
  margin-top: 15px;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 15px 60px;
  background: linear-gradient(
    rgb(255, 51, 90) -11.36%,
    rgb(139, 16, 35) 135.23%
  );
  transition: 0.4s;

  &:hover {
    opacity: 0.5;
    transition: 0.4s;
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
