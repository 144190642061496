.header {
  background: linear-gradient(black, rgba(0, 0, 0, 0.4));
}

.btnmenu {
  width: 28px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  //   display: none;
  z-index: 350;
  &:before,
  &:after,
  span {
    content: "";
    width: 100%;
    height: 2px;
    background: white;
    display: block;
    position: absolute;
    transition: 0.4s;
  }
  &:before {
    transform: rotate(0deg) translate(0px, 0px);
  }
  &:after {
    bottom: 0;
    transform: rotate(0deg) translate(0px, 0px);
  }
  span {
    top: 50%;
    transform: translateY(-50%);
  }

  &.active {
    &:before {
      transform: rotate(45deg) translate(9px, 4px);
      transition: 0.4s;
    }
    span {
      opacity: 0;
      transition: 0.4s;
    }
    &:after {
      transform: rotate(-45deg) translate(9px, -4px);
      transition: 0.4s;
    }
  }
}

.nav {
  width: 100vw;
  height: 100vh;
  background: #161616;
  position: fixed;
  top: 0;
  left: 0;
  transform: initial;
  -webkit-transform: initial;
  -moz-transform: initial;
  -ms-transform: initial;
  -o-transform: initial;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  ul {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 30px auto;
      a {
        padding: 20px 0;
        color: white;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
